.indoorHeader {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	gap: 0.5rem;
	h4 {
		margin: 0;
	}

	p {
		margin: 0;
	}
}
.primaryText {
	display: flex;
	justify-content: space-between;
}

//mobile view
@media only screen and (max-width: 600px) {
	.indoorHeader {
		h4 {
			font-size: 20px;
			line-height: 28px;
		}
		p {
			font-size: 14px;
			line-height: 22px;
		}
	}
}

.mt20{
	margin-top: 20px;
}

.indoorPresentationCardContent {
	display: flex;
	flex-direction: column;
}

.selectors {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	@media (max-width: 600px) {
		grid-template-columns: 1fr;
	}
}


.format,
.format_selected {
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 20px;
	letter-spacing: -0.03em;
	text-align: left;
	width: 100%;
	display: flex;
	padding: 1.5rem;
	gap: 0.5rem;
	justify-content: flex-start;
	align-items: center;
	height: 148px;
	flex-direction: column;
	border-radius: 8px;
	cursor: pointer;
}

.format {
	border: 1px solid #ced4da;
	.icon {
		fill: #212529;
	}
	.title {
		span {
			color: #6c757d;
		}
	}

	&:hover {
		background: rgba(0, 123, 255, 0.1);
		color: #007bff;
		border: 1px solid #007bff;

		.icon {
			fill: #007bff;
		}
		.title span {
			color: #007bff;
		}
	}
}
.format_selected {
	background: #007bff;
	border: 1px solid #007bff;
	color: #ffffff;
	.icon {
		background-color: none;
		fill: white;
	}
	.title {
		span {
			color: rgba(255, 255, 255, 0.5);
		}
	}

	&:hover {
		background: #006bdd;
	}
}

.format_disabled {
	opacity: 0.6;
}
.title {
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.04em;

	color: inherit;

	span {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		text-align: center;
		letter-spacing: -0.03em;
	}
}
.icon {
	min-width: 24px;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	border-radius: 50%;
}

//mobile view
@media only screen and (max-width: 600px) {
	.title {
		text-align: start;
		font-size: 14px;
	}
	.format,
	.format_selected {
		padding: 1rem;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
		height: auto;
		gap: 1rem;
	}
	.icon {
		align-items: flex-start;
	}
}

.ourdoorMassCoverage {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.ourdoorMassCoverageHeader {
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	h5 {
		line-height: 28px;
		margin: 0;
	}
	p {
		margin: 0;
	}
}
.ourdoorMassCoverageHeader_primary {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
}

@media only screen and (max-width: 600px) {
	.ourdoorMassCoverage {
		gap: 1rem;
	}

	.ourdoorMassCoverageHeader {
		p {
			font-size: 14px;
			line-height: 22px;
			letter-spacing: -0.03em;
		}
	}
}

.controlCity{
	width: 100%;
}

.controlBudget{
	width: 50%;
}


.totals {
	margin-top: 16px;
}

.totalsItem {
	display: flex;
	justify-content: space-between;
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	padding: 8px 0;
}

.bb {
	border-bottom: 1px solid #ced4da;
}
