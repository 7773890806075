/*desktop*/
.submenu {
	margin-left: 15px;
	position: relative;
	top: 10px;
}

.submenuItem {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 18px;
	position: relative;
	color: #6c757d;
}

.submenuItem .circle {
	content: '';
	width: 12px;
	height: 12px;

	border-radius: 50%;
	background-color: #ced4da;
	margin-right: 30px;
}

.submenuItem .label {
	font-size: 14px;
}

.submenuItem::before {
	content: '';
	width: 2px;
	height: 30px;
	background: #ced4da;
	position: absolute;
	top: -22px;
	left: 5px;
}

.submenuItem[data-active='active'] {
	color: #212529;
}

.submenuItem[data-active='active'] .circle {
	background: #007bff;
}

.submenuItem[data-active='active']::before {
	background: #007bff;
}

/*tablet*/
.submenuTablet {
	display: flex;
	margin-left: 8px;
}

.submenuItemTablet {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	margin-right: 8px;
	cursor: pointer;
}

.submenuItemTablet .circle {
	content: '';
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #ced4da;
	margin-right: 8px;
	min-width: 12px;
	min-height: 12px;
}

.submenuItemTablet .label {
	font-size: 14px;
}

.submenuItemTable[data-active='active'] {
	color: #212529;
}

.submenuItemTablet[data-active='active'] .circle {
	background: #007bff;
}

/*mobile*/
.submenuMobile {
	margin-left: 14px;
	display: flex;
}

.submenuItemMobile {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: 18px;
	position: relative;
	color: #6c757d;
	justify-content: flex-end;
}

.submenuItemMobile .circle {
	content: '';
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background-color: #ced4da;
	margin-right: 30px;
}

.submenuItemMobile::before {
	content: '';
	width: 30px;
	height: 2px;
	background: #ced4da;
	position: absolute;
	top: 18px;
	left: -30px;
}

.submenuItemMobile[data-active='active'] {
	color: #212529;
}

.submenuItemMobile[data-active='active'] .circle {
	background: #007bff;
}

.submenuItemMobile[data-active='active']::before {
	background: #007bff;
}

.container {
	min-width: 700px;
	max-width: 800px;
	//flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	gap: 16px;
	position: relative;

	@media (min-width: 1440px) {
		min-width: 800px;
	}

	@media (max-width: 1132px) {
		max-width: 100%;
		min-width: auto;
	}
}

.card {
	background-color: #ffffff;
	border: none;
	border-radius: 0.6rem;
	width: 100%;

	@media (max-width: 600px) {
		padding: 24px 16px;
	}
}

.controls {
	position: absolute;
	top: calc(100% + 24px);
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-flow: row-reverse;
	pointer-events: all;
	z-index: 0;
}

// download requirements modal style
.modalContainer {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 0px;
	position: absolute;
	height: 100%;
	left: calc(50% - 540px / 2);
	width: 540px;

	@media only screen and (max-width: 600px) and (min-width: 320px) {
		position: absolute;
		width: 100vw;
		height: 1192px;
		left: 0px;
		bottom: 0px;
		background: #ffffff;
		border-radius: 8px 8px 0px 0px;
	}
}

.modalHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 20px 24px;
	gap: 24px;
	width: 540px;
	height: 68px;
	flex: none;
	order: 0;
	align-self: stretch;
	flex-grow: 0;

	& > button {
		height: 24px;
		order: 1;
		flex-grow: 0;
	}
}

//urinfostyle
.urinfocontainer.urinfocontainer {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
	width: 460px;
	height: 458px;
	flex: none;
	order: 26;
	align-self: stretch;
	flex-grow: 0;
	margin-top: 7px;

	@media only screen and (max-width: 600px) and (min-width: 320px) {
		width: 100vw;
		margin-left: 10px;
	}
}

.urinfo {
	width: 460px;
	height: 192px;
	font-family: 'Inter';
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: -0.03em;
	color: #212529;
	flex: none;
	order: 1;
	flex-grow: 0;
	margin-top: 4px;
	margin-left: -10px;

	& > ol {
		padding-left: 1em;
	}

	@media only screen and (max-width: 600px) and (min-width: 320px) {
		width: 100vw;
	}
}

//urinfo image style
.urinfoimgExpandedWrapper {
	position: fixed;
	z-index: 9999;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);

	@media only screen and (max-width: 600px) and (min-width: 320px) {
		width: 100vw;
		top: 20px;
	}
}

.urinfoimg {
	width: 100%;
	height: auto;
	max-width: 90vw;
	max-height: 90vh;
	border-radius: 8px;
	object-fit: contain;
	cursor: pointer;
	transition: all 0.5s ease-in-out;

	@media only screen and (max-width: 600px) and (min-width: 320px) {
		width: 288px;
		margin-top: 25px;
		height: 144px;
	}
}

.urinfoimg.expanded {
	max-width: none;
	max-height: none;
}

.expanded {
	overflow: hidden;
}

.dopInfo {
	white-space: nowrap;
	margin-top: '4px';

	@media only screen and (max-width: 600px) and (min-width: 320px) {
		width: 288px;
		height: 44px;
		font-family: 'Inter';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		letter-spacing: -0.03em;
		color: #212529;
		flex: none;
		order: 1;
		align-self: stretch;
		flex-grow: 0;
		white-space: pre-wrap;
	}
}

.legalInformationWrapper {
	margin-left: -10px;
}
.modal-fullscreen {
	width: 100vw;
	height: 100vh;
	margin: 0;
	padding: 0;
}

.modal-iframe {
	width: 100%;
	height: 100%;
	border: none;
}
