.create-campaign-page {
  position: relative;
  padding: 39px 165px 88px;

  &__title {
    margin-bottom: 24px;
  }

  &__back {
    color: #6c757d;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    position: absolute;
    top: 50px;
    left: 24px;
    display: flex;
    align-items: center;
  }

  &__back-arrow {
    margin-right: 8px;
  }

  &__body {
    display: flex;
  }

  &__subtitle {
    margin-bottom: 24px;
  }

  &__form {
    padding: 40px;
    flex-grow: 1;
    position: relative;
    max-width: 800px;
    min-width: 800px;
  }

  &__stepper {
    list-style: none;
    height: fit-content;
    position: sticky;
    top: 100px;
  }

  &__step {
    display: flex;
    align-items: center;
    color: #6c757d;
    margin-bottom: 16px;
    white-space: nowrap;

    &_active,
    &_completed {
      color: #212529;
    }

    &_avaliable {
      cursor: pointer;
    }

    flex-direction: column;
    align-items: start;
  }

  &__step_completed &__step-num {
    background: rgba(0, 123, 255, 0.1);
    color: #007bff;
  }

  &__step_active &__step-num {
    background: #007bff;
    color: #ffffff;
  }

  &__step-num {
    width: 40px;
    height: 40px;
    background: #ffffff;
    font-size: 16px;
    line-height: 24px;
    color: #6c757d;
    margin-right: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input-group {
    margin-bottom: 24px;
  }

  .input-group > .form-control,
  .input-group > .form-select {
    width: 100%;
  }

  &__buttons {
    position: absolute;
    top: calc(100% + 24px);
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-flow: row-reverse;
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
  }

  &__dates {
    display: flex;
    align-items: flex-end;
  }

  &__dates-separator {
    align-self: center;
    margin: 0 8px;
  }

  &__separator {
    margin-top: 24px;
    margin-bottom: 24px;
    border: 1px solid #ced4da;
  }

  &__booking {
    display: flex;
    position: relative;
    margin-bottom: 24px;
  }

  &__booking-delete {
    position: absolute;
    right: 0;
    cursor: pointer;
  }

  &__booking-image {
    width: 80px;
    height: 80px;
    object-fit: contain;
  }

  &__booking-body {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }
  label {
    letter-spacing: -0.04em;
  }
  .card {
    border: none;
    border-radius: 0.6rem;

  }
  &__card {
    border: none;
    border-radius: 0.6rem;
    max-width: 800px;
    min-width: 700px;
    flex-grow: 1;
  }

  div {
    letter-spacing: -0.03em;
  }
  .rounded-btn-group {
    .btn {
      border-radius: 0;
    }
    .btn-group-vertical:first-child {
      .btn:first-child {
          border-top-left-radius: .25rem;
      }
      .btn:last-child {
        border-bottom-left-radius: .25rem;
      }
    }
    .btn-group-vertical:last-child {
      .btn:first-child {
        border-top-right-radius: .25rem;
      }
      .btn:last-child {
        border-bottom-right-radius: .25rem;
      }
    }

  }
}
//tablet
@media (max-width: 1132px) {
  .create-campaign-page {
    padding: 56px 24px 88px;
    .card {
      max-width: 100%;
      min-width: auto;
    }
    &__body {
      flex-flow: column-reverse;
    }

    &__back {
      top: 24px;
    }

    &__stepper {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      position: initial;
      padding: 0;
      margin: 0;
    }

    &__stepper-block {
      display: flex;
      flex-wrap: nowrap;
    }

    &__step-label {
      display: none;
    }

    &__step_active &__step-label {
      display: inline;
    }
  }
}

//mobile
@media (max-width: 600px) {
  .create-campaign-page {
    padding: 0;
    &__body {
      padding: 0 24px 88px 24px;
    }
    &__title {
      height: 40px;
      background: white;
      border-bottom: 1px solid #CED4DA;
      padding: 10px 0 10px 0;
      white-space: nowrap;
    }
    h1 {
      font-size: 0.9em;
      line-height: 20px;
      text-align: center;
      letter-spacing: -0.03em;
      font-weight: normal;
      color: #212529;
    }

  }
  .create-campaign-page__back {
    top: 9px;
    left: 13px;
  }
  .create-campaign-page__stepper {
    justify-content: center;
  }
  &-page__subtitle {
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
  }
  .create-campaign-page__form {
    padding: 32px 16px
  }
}

@media (min-width:1921px) {
  .create-campaign-page {

    &__body {
      //display: flex;
      //justify-content: center;
    }
    &__title {
      //text-align: center;
    }
  }
}
.input-group {
  margin-bottom: 24px;
}

.input-group > .form-control,
.input-group > .form-select {
  width: 100%;
}