.container {
  min-width: 700px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  @media (max-width: 1132px) {
    max-width: 100%;
    min-width: auto;
  }
}

.card {
  padding: 40px;
  background-color: #ffffff;
  border: none;
  border-radius: 0.6rem;
  width: 100%;
}

.controls {
  position: absolute;
  top: calc(100% + 24px);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row-reverse;
  pointer-events: all;
  z-index: 0;
}

.actionsPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px;
  gap: 24px;
  position: fixed;
  width: 1110px;
  height: 68px;
  bottom: 40px;
  z-index: 100;
  background: #212529;
  box-shadow: 0px 4px 4px rgba(33, 37, 41, 0.15);
  border-radius: 8px;


  @media only screen and (max-width: 600px) and (min-width: 320px){
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 16px;
    gap: 16px;
    width: 288px;
    height: 104px;
    position: fixed;
    bottom: 30px;

    left: 60px;

    box-shadow: 0px 4px 4px rgba(33, 37, 41, 0.15);
    border-radius: 8px;
    flex: none;
    order: 2;
    flex-grow: 0;
  }


  &>h1 {
    width: 848px;
    height: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 1;
    margin-top: 10px;
  }
}


.actionsPanel>h1 {
  @media only screen and (max-width: 600px) {
    width: 256px;
    height: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

.btnApprove {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 190px;
  height: 36px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border-radius: 4px;



  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 256px;
    height: 36px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
  }
}

.btnA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  width: 190px;
  height: 36px;
  background: #E9ECEF;
  border-radius: 4.8px;
  flex: none;
  order: 0;
  flex-grow: 0;



  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    width: 256px;
    height: 36px;
    background: #007BFF;
    border-radius: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
}


.btnContain {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 166px;
  height: 24px;
  flex: none;
  order: 0;
  flex-grow: 0;



  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 232px;
    height: 24px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

  }
}




.buttonText {
  width: 166px;
  height: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  color:white;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 10px;



  @media only screen and (max-width: 600px) {

    width: 166px;
    height: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.03em;
    color: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;

  }
}