.toast-notification {
	position: fixed;
	top: 40px;
	right: 0;
	left: 0;
	margin: 0 auto;
	width: fit-content;
	z-index: 1000;
	max-height: 44px;
	.alert {
		padding: 10px 20px 10px 20px;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		color: #383d41;
	}
	&__toast {
		display: flex;
		align-items: center;
		line-height: 24px;
	}
	button {
		margin-left: 22px;
	}
	&__close {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-left: 22px;
	}
}
