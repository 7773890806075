.container {
	width: 100%;
	overflow: hidden;
}

.dropZone {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 74px;
	border: 1px dashed #ced4da;
	padding: 13px 16px;
	transition: border 0.1s ease-in-out;
	border-radius: 4px;
	&[data-highlight='true'] {
		border: 2px dashed #007bff;
	}
	&[data-error='true'] {
		border-color: #dc3545;
	}
	&[data-loaded='true'] {
		border: 1px dashed green;
	}
	&.noPadding {
		padding: 0;
	}
}

.cancelButton {
	margin-left: 15px;
}

.noValue {
	display: flex;
	justify-content: center;
	width: 100%;
	pointer-events: none;
	@media (max-width: 600px) {
		flex-direction: column;
		text-align: center;
	}

	label {
		color: #007bff;
		margin-right: 5px;
		cursor: pointer;
		font-size: 20px;
		@media (max-width: 600px) {
			font-size: 16px !important;
		}
		input {
			display: none;
		}
		pointer-events: all;
	}
	span {
		color: #6c757d;
		font-size: 20px;
		@media (max-width: 600px) {
			font-size: 16px !important;
		}
		pointer-events: none;
	}
}

.value {
	display: flex;
	width: 100%;
	flex-wrap: nowrap;
	gap: 16px;
	align-items: center;

	.imageContainer {
		width: 80px;
		height: 80px;
		background-color: #f5f5f5;
		border: 1px solid #ced4da;
		border-radius: 4px;
		overflow: hidden;
		display: grid;
		align-items: center;
		img {
			object-fit: contain;
			width: 100%;
		}
	}
	.data {
		display: flex;
		flex: 1 1 auto;
		overflow: hidden;
		gap: 4px;
		flex-direction: column;
		.name {
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.04em;
			color: #212529;
		}
		.sizes {
			font-size: 14px;
			line-height: 22px;
			letter-spacing: -0.04em;
			color: #6c757d;
		}
	}
	.delete {
		width: 32px;
		height: 32px;
		font-size: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		border-radius: 4px;
		&:hover {
			background-color: #f5f5f5;
			svg > path {
				fill: red;
			}
		}
	}
}

.loadingContainer {
	display: flex;
	flex-wrap: nowrap;
	gap: 16px;
	align-items: center;
	width: 100%;

	.imageContainer {
		width: 80px;
		height: 80px;
		background-color: #f5f5f5;
		border: 1px solid #ced4da;
		border-radius: 4px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.data {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		gap: 4px;
		.filename {
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: -0.03em;
			color: #212529;
		}
		.bar {
			background: #ced4da;
			border-radius: 4px;
			width: 100%;
			height: 4px;
			overflow: hidden;
			.blue {
				height: 100%;
				background-color: #007bff;
				border-radius: 4px;
			}
		}
		.info {
			display: flex;
			justify-content: space-between;
			font-size: 12px;
			line-height: 20px;
			letter-spacing: -0.03em;
			color: #6c757d;
		}
	}
	.cancel {
		width: 32px;
		height: 32px;
		font-size: 32px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
}

.description {
	width: 100%;
	text-align: center;
	color: #6c757d;
	font-size: 12px;
	line-height: 14px;
	letter-spacing: -0.03em;
	font-weight: 400;
	margin-top: 4px;
}

.loaded {
	@extend .description;
	color: green;
}
