.item{
	align-items: center;
    border: 1px solid #ced4da;
    border-radius: 16px;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 20px 16px;
	max-width: 100%;

	&:hover{
		background-color: rgba(0, 123, 255, .1);
		border-color: #007bff;
	}

	&>div{
		display: flex;
		align-items: center;
		gap: 1rem;
	}
}

.status{
	align-items: center;
    background-color: rgba(40, 167, 69, .1);
    border-radius: 12px;
    color: #28a745 !important;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    gap: .5rem;
    line-height: 22px;
    padding: 8px;

	align-items: center;
        border-radius: 10px;
        display: flex;
        font-size: 12px;
        font-weight: 400;
        justify-content: center;
        line-height: 18px;
        width: 100%;


	&>svg{
		fill: #28a745 !important;
		height: 1rem;
		width: 1rem;
	}
}

.budget{
	white-space: nowrap;
}