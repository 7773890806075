.notificationIcon {
	height: 64px;
	width: 64px;
	min-width: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	cursor: pointer;
	@media (max-width: 600px) {
		width: 51px;
		height: 51px;
	}
	&:hover {
		background-color: #f5f5f5;
		svg {
			path {
				fill: #212529;
			}
		}
	}
}
.NewNotificationDisplay {
	position: absolute;
	left: 41px;
	top: 17px;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background: #007bff;
	@media (max-width: 600px) {
		left: 40px;
		top: 10px;
	}
}
