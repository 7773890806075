.autoPostingLegalInfoCard {
	margin-bottom: 1.5rem;
}
.autoPostingLegalInfoContainer {
	display: flex;
	flex-direction: column;
	gap: 1.5rem;

	h2 {
		margin: 0;
	}
}
.autoPostingLegalInfoBody {
	display: flex;
	gap: 1rem;
	input[type='checkbox'] {
		width: 36px;
		height: 20px;
	}
}
.autoPostingText {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	p {
		margin: 0;
	}
}

.autoPostingTextTitleContainer {
	display: flex;
	align-items: center;
	gap: 0.25rem;
}
.autoPostingTextTitle {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}
.autoPostingTextInfo {
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;

	ul {
		color: #6c757d;
		padding-left: 0.25rem;
		list-style-position: inside;
	}
}

//mobile view
@media only screen and (max-width: 600px) {
	.autoPostingLegalInfoCard {
		padding: 1rem;
	}
	.autoPostingLegalInfoContainer {
		gap: 1rem;
		h2 {
			font-size: 28px;
			font-weight: 500;
			line-height: 32px;
			letter-spacing: -0.05em;
		}
	}
	.autoPostingTextTitleContainer {
		align-items: flex-start;
	}
	.autoPostingText {
		gap: 0.25rem;
	}
	.autoPostingLegalInfoBody {
		gap: 0.75rem;

		input[type='checkbox'] {
			width: 28px;
			height: 16px;
		}
	}
	.autoPostingTextTitle {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}
	.autoPostingTextInfo {
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		text-align: left;
	}
}
