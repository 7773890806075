.mediaformats-page {
	background: #ffffff;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	&__title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #ced4da;
		padding: 18px 40px;

		h1 {
			font-family: Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 40px;
			line-height: 48px;
			/* identical to box height, or 120% */
			margin: 0;
			padding: 0;
			letter-spacing: -0.05em;
		}
		button {
			height: 36px;
			font-family: Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.04em;
		}
	}
	&__tabs {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */
		color: #6c757d;
		letter-spacing: -0.05em;
		margin-top: 24px;
		padding: 0 40px;
		border-bottom: 1px solid #ced4da;

		.tab {
			padding: 9px 16px 7px 16px;
			border-bottom: 2px solid transparent;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			span {
				height: 18px;
				border-radius: 4px;
				padding: 3px 4px 3px 4px;
				margin-left: 4px;
				font-style: normal;
				font-weight: 500;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: -0.05em;
			}
		}
		.active {
			color: #007bff;
			border-bottom: 2px solid #007bff;
		}
	}
	&__content {
		background: #fff;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: row;
		width: 100%;
		flex: 1 0 auto;
		.col {
			width: calc(50% - 1px);
			min-width: calc(50% - 1px);
		}

		.col_left {
			padding: 24px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.list-item {
				width: 100%;
				height: 64px;
				display: flex;
				justify-content: space-between;
				//max-width: 548px;
				cursor: default;
				.left {
					display: flex;
					justify-content: center;
					flex-direction: column;
					flex-wrap: nowrap;
					align-items: flex-start;
					font-family: Inter;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					/* identical to box height, or 150% */
					padding-left: 16px;
					letter-spacing: -0.04em;
					span {
						font-family: Inter;
						font-style: normal;
						font-weight: normal;
						font-size: 14px;
						line-height: 22px;
						/* identical to box height, or 157% */

						letter-spacing: -0.04em;

						/* Text/Secondary */

						color: #6c757d;
					}
				}
				.right {
					display: flex;
					justify-content: flex-start;
					flex-direction: row;
					flex-wrap: nowrap;
					align-items: center;
					padding-right: 16px;
					.badge {
						height: 18px;
						padding: 3px 4px;
						font-family: Inter;
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 12px;
						letter-spacing: -0.05em;
						margin-left: 8px;
					}
					i {
						font-size: 18px;
						padding: 10px;
						margin-right: 8px;
						cursor: pointer;
						color: #6c757d;
						&:hover {
							color: #212529;
						}
					}
				}
				&:hover {
					background: #f5f5f5;
				}
			}
			.selected {
				background: #f5f5f5;
			}
		}
		.separator {
			min-height: calc(100vh - 294px);
			border-right: 1px solid #ced4da;
			content: '';
			width: 1px;
			max-width: 1px;
			display: inline-block;
		}
		.col_right {
			padding: 24px 40px 0 24px;
			min-height: 100%;
			.media-details {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				flex-wrap: nowrap;
				min-height: 100%;
				&__name {
					display: flex;
					justify-content: space-between;
					flex-wrap: nowrap;
					align-items: flex-start;
					h5 {
						font-family: Inter;
						font-style: normal;
						font-weight: 500;
						font-size: 20px;
						//line-height: 28px;
						/* identical to box height, or 140% */
						letter-spacing: -0.04em;
						/* Text/Dark */
						color: #212529;
						margin-bottom: 13px;
					}
					span {
						height: 18px;
						padding: 3px 4px 3px 3px;
						font-family: Inter;
						font-style: normal;
						font-weight: 500;
						font-size: 12px;
						line-height: 12px;
						/* identical to box height, or 100% */
						letter-spacing: -0.05em;
						margin-left: 8px;
					}
				}
				&__type-col {
					display: flex;
					flex-direction: column;
					flex-wrap: nowrap;
					justify-content: flex-start;
				}
				.type-row {
					display: flex;
					flex-wrap: nowrap;
					justify-content: space-between;
					border-bottom: 1px solid #ced4da;
					padding-bottom: 7px;
					font-family: Inter;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					/* identical to box height, or 150% */
					letter-spacing: -0.04em;
					margin-top: 8px;
					span:first-of-type {
						color: #6c757d;
					}
					//desc field
					&:last-of-type {
						flex-direction: column;
						white-space: pre-wrap;
						border: none;
						span:first-of-type {
							margin-bottom: 3px;
						}
						span:last-of-type {
							margin-bottom: 15px;
						}
					}
				}
				&__control_panel {
					height: 80px;
					border-top: 1px solid #ced4da;
					justify-content: center;
					display: flex;
					align-items: center;
					.control-btn {
						min-height: 100%;
						//width: 89px;
						display: flex;
						flex-direction: column;
						align-items: center;
						color: #6c757d;
						justify-content: center;
						padding: 12px;
						cursor: pointer;
						margin-top: 1px;
						&:hover {
							color: #007bff;
							background: #f5f5f5;
							i {
								fill: #007bff;
							}
						}
						i {
							display: flex;
							justify-content: center;
							flex-direction: column;
							font-size: 20px;
						}
						span {
							font-size: 16px;
						}
					}
				}
			}
		}
	}

	&__nocontent {
		flex: 1 1 0;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		span {
			font-size: 40px;
			margin-bottom: 16px;
		}
		h2 {
			font-family: Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 24px;
			line-height: 32px;
			/* identical to box height, or 133% */

			text-align: center;
			letter-spacing: -0.04em;

			/* Text/Secondary */

			color: #6c757d;
			margin-bottom: 40px;
		}
	}
}

.media-details {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex-wrap: nowrap;
	min-height: 100%;
	&__name {
		display: flex;
		justify-content: space-between;
		flex-wrap: nowrap;
		align-items: flex-start;
		h5 {
			font-family: Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			//line-height: 28px;
			/* identical to box height, or 140% */
			letter-spacing: -0.04em;
			/* Text/Dark */
			color: #212529;
			margin-bottom: 13px;
		}
		span {
			height: 18px;
			padding: 3px 4px 3px 3px;
			font-family: Inter;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			/* identical to box height, or 100% */
			letter-spacing: -0.05em;
			margin-left: 8px;
		}
	}
	&__placements {
		color: #212529;
		a {
			color: #212529;
		}
	}
	&__type-col {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		justify-content: flex-start;
	}
	.type-row {
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
		border-bottom: 1px solid #ced4da;
		padding-bottom: 7px;
		font-family: Inter;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		/* identical to box height, or 150% */
		letter-spacing: -0.04em;
		margin-top: 8px;
		span:first-of-type {
			color: #6c757d;
		}
		//desc field
		&:last-of-type {
			flex-direction: column;
			white-space: pre-wrap;
			border: none;
			span:first-of-type {
				margin-bottom: 3px;
			}
			span:last-of-type {
				margin-bottom: 15px;
			}
		}
	}
}

//BOttomSheet
.mediaformats-page__control_panel {
	height: 80px;
	border-top: 1px solid #ced4da;
	justify-content: center;
	display: flex;
	align-items: center;
	width: 100%;
	.control-btn {
		min-height: 100%;
		//width: 89px;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #6c757d;
		justify-content: center;
		padding: 12px;
		cursor: pointer;
		margin-top: 1px;
		&:hover {
			color: #212529;
			background: #f5f5f5;
		}
		i {
			display: flex;
			justify-content: center;
			flex-direction: column;
			font-size: 20px;
		}
		span {
			font-size: 16px;
		}
	}
}
