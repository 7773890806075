.container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 10px;
	@media (max-width: 600px) {
		grid-template-columns: 1fr;
	}
}

.teletarget{
	width: 80px;
height: 18.41px;


/* Inside auto layout */

flex: none;
order: 2;
flex-grow: 0;
}
.format {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 148px;
	flex-direction: column;
	@media (max-width: 600px) {
		height: 120px;
		justify-content: flex-start;
		flex-direction: row;
	}
	border: 1px solid #ced4da;
	border-radius: 8px;
	cursor: pointer;
	color: #212529;

	&:hover {
		background: rgba(0, 123, 255, 0.1);
		color: #007bff;
		border: 1px solid #007bff;

		.title span {
			color: #007bff;
		}
	}

	
	.title {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.04em;
		color: inherit;
		padding: 0 24px;
		span {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			text-align: center;
			letter-spacing: -0.03em;
			color: #6c757d;
			//padding: 4px 24px 0px 24px;
			text-align: center;
		}
	}
	.icon {
		margin-bottom: 5px;
		min-width: 24px;
		@media (max-width: 600px) {
			align-self: flex-start;
			margin: 30px 15px 0;
		}
	}
}
.formatSelected {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 148px;
	flex-direction: column;
	@media (max-width: 600px) {
		height: 120px;
		justify-content: flex-start;
		flex-direction: row;
	}
	background: #007bff;
	border: 1px solid #007bff;
	border-radius: 8px;
	cursor: pointer;
	color: #ffffff;

	&:hover {
		background: #006bdd;
	}

	.icon > svg > path {
		fill: #ffffff;
	}

	.title {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.04em;
		color: inherit;
		padding: 0 24px;
		span {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			text-align: center;
			letter-spacing: -0.03em;
			color: rgba(255, 255, 255, 0.5);
		}
	}
	.icon {
		margin-bottom: 5px;
		min-width: 24px;
		@media (max-width: 600px) {
			align-self: flex-start;
			margin: 30px 15px 0;
		}
	}
}

.formatDisabled {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 148px;
	flex-direction: column;
	opacity: 0.5;
	@media (max-width: 600px) {
		height: 120px;
		justify-content: flex-start;
		flex-direction: row;
	}
	border: 1px solid #ced4da;
	border-radius: 8px;
	color: #212529;

	.title {
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		letter-spacing: -0.04em;
		color: inherit;
		padding: 0 24px;
		@media (max-width: 600px) {
			flex: 1 1 auto;
		}

		span {
			font-family: 'Inter';
			font-style: normal;
			font-weight: 400;
			font-size: 12px;
			line-height: 20px;
			text-align: center;
			letter-spacing: -0.03em;
			color: #6c757d;
		}
	}
	.icon {
		margin-bottom: 5px;
		min-width: 24px;
		@media (max-width: 600px) {
			align-self: flex-start;
			margin: 30px 15px 0;
		}
	}
}
