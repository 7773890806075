.header {
	position: fixed;
	left: 0;
	top: 0;
	display: flex;
	flex-wrap: nowrap;
	width: 100%;
	height: 65px;
	@media (max-width: 600px) {
		height: 51px;
	}
	background: #ffffff;
	border-bottom: 1px solid #ced4da;
	z-index: 15;
	& > div {
		height: 100%;
		border-right: 1px solid #ced4da;
		&:last-of-type {
			border-right: none;
		}
	}
}

.burger {
	font-size: 32px;
	display: grid;
	place-items: center;
	width: 64px;
	min-width: 64px;
	cursor: pointer;
	@media (max-width: 600px) {
		font-size: 24px;
		min-width: 51px;
		width: 51px;
	}
	@media (min-width: 1132px) {
		display: none;
	}
}
.logo {
	display: flex;
	align-items: center;
	padding-left: 24px;
	padding-right: 24px;
	justify-content: center;
	cursor: pointer;
	@media (min-width: 1132px) {
		min-width: 240px;
	}
}
.pageTitle {
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	padding: 0 40px;
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	@media (max-width: 600px) {
		padding: 0;
	}
}
